import axios from 'axios'
import store from '@/store'
import { showFailToast, showLoadingToast, showSuccessToast } from 'vant'
// import router from '@/router'
// import { apiPostLogin } from '@/api/loginApi'
// import storage from '@/storage'
// 创建一个 axios 实例

const service = axios.create({
  baseURL: '',
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  headers: { // 设置后端需要的传参类型
    'Content-Type': 'application/json;charset=utf-8'
  }
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // console.log('触发请求拦截器------config：', config)
  if (store.getters.getToastState) {
    showLoadingToast({
      message: '加载中...',
      forbidClick: true
    })
    store.commit('delToastState')
  }
  config.headers.token = store.getters.getToken
  return config
}, function (error) {
  // 对请求错误做些什么
  // console.log('触发请求拦截器------error：', error)
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // console.log('触发响应拦截器------response：', response)
  if (Number(response.data.retCode) === 0 || response.data.status === 0 || response.data.success) {
    if (store.getters.getToastState) {
      showSuccessToast(response.data.retMsg)
      store.commit('delToastState')
    }
    return response
  } else if (Number(response.data.retCode) === 1000) {
    store.commit('delToken')
    store.commit('delUserInfo')
    // apiPostLogin(store.getters.getLoginInfo).then(res => {
    //   if (res) {
    //     store.commit('setToken', res.data.token)
    //     storage.set('userInfo', res.data.memberMap)
    //     return service.request(response.config)
    //   }
    // })
  } else {
    showFailToast(response.data.retMsg)
    store.commit('delToastState')
  }
  // return response.data.retCode === '0' ? response : Promise.reject(response.data.retMsg)
}, function (error) {
  // 对响应错误做点什么
  // console.log('触发响应拦截器------error：', error)
  if (error.response) {
    console.log(error.response)
    switch (error.response.status) {
      case 4001:
        // 可能是token过期，清除它
        store.commit('delToken')
        store.commit('delUserInfo')
        // router.replace({ // 跳转到登录页面
        //   path: '/login'
        //   // 将跳转的路由path作为参数，登录成功后跳转到该路由
        //   // query: { redirect: router.currentRoute.fullPath }
        // })
    }
  }
  return Promise.reject(error.response.data)
})

export default service
