import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vueEsign from 'vue-esign'
import LongPress from '@/utils/longPress'

import '@/assets/styles/reset.css'
import '@/assets/styles/common.css'
import '@/assets/styles/config.css'
import '@/assets/styles/vantcon.less'
import '@/assets/iconfont/font.css'
import 'vant/es/toast/style'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(vueEsign)
app.use(LongPress)
app.provide('$axios', axios)
app.mount('#app')
