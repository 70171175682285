import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import storage from '@/storage'
// 创建路由规则
const routes = [{
  name: 'login',
  path: '/login',
  component: () => import('@/views/Login'),
  children: [{
    name: 'login-index',
    path: '/login-index',
    component: () => import('@/views/LoginIndex'),
    children: [{
      name: 'login-index-forget',
      path: '/login-index-forget',
      component: () => import('@/views/LoginIndexForget')
    }]
  }]
}, {
  path: '/',
  component: () => import('@/views/Layou'),
  children: [{
    name: 'home',
    path: '',
    meta: {
      title: '首页',
      keepAlive: true
    },
    component: () => import('@/views/Home')
  }, {
    name: 'select',
    path: '/select',
    meta: {
      title: '精选',
      keepAlive: true
    },
    component: () => import('@/views/Select')
  }, {
    name: 'destination',
    path: '/destination',
    meta: {
      title: '目的地',
      keepAlive: true
    },
    component: () => import('@/views/Destination')
  }, {
    name: 'my',
    path: '/my',
    meta: {
      title: '我的'
    },
    component: () => import('@/views/My')
  }]
}, {
  name: 'home-ranking',
  path: '/home-ranking',
  component: () => import('@/views/HomeRanking')
}, {
  name: 'home-invite',
  path: '/home-invite',
  component: () => import('@/views/HomeInvite'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'select-order-private',
  path: '/select-order-private',
  component: () => import('@/views/SelectOrderPrivate')
}, {
  name: 'select-order-company',
  path: '/select-order-company',
  component: () => import('@/views/SelectOrderCompany')
}, {
  name: 'destination-search',
  path: '/destination-search',
  component: () => import('@/views/DestinationSearch')
}, {
  name: 'my-setting',
  path: '/my-setting',
  component: () => import('@/views/MySetting')
}, {
  name: 'my-setting-info',
  path: '/my-setting-info',
  component: () => import('@/views/MySettingInfo'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-setting-blacklist',
  path: '/my-setting-blacklist',
  component: () => import('@/views/MySettingBlacklist'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-setting-safety',
  path: '/my-setting-safety',
  component: () => import('@/views/MySettingSafety'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-setting-safety-password',
  path: '/my-setting-safety-password',
  component: () => import('@/views/MySettingSafetyPassword'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-setting-safety-signout',
  path: '/my-setting-safety-signout',
  component: () => import('@/views/MySettingSafetySignout'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-setting-pay',
  path: '/my-setting-pay',
  component: () => import('@/views/MySettingPay'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-setting-protocol',
  path: '/my-setting-protocol',
  component: () => import('@/views/MySettingProtocol')
}, {
  name: 'my-setting-community',
  path: '/my-setting-community',
  component: () => import('@/views/MySettingCommunity')
}, {
  name: 'my-setting-community-member',
  path: '/my-setting-community-member',
  component: () => import('@/views/MySettingCommunityMember')
}, {
  name: 'my-attention',
  path: '/my-attention',
  component: () => import('@/views/MyAttention'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-fans',
  path: '/my-fans',
  component: () => import('@/views/MyFans'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-money',
  path: '/my-money',
  component: () => import('@/views/MyMoney'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-article',
  path: '/my-article',
  component: () => import('@/views/MyArticle'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-praise',
  path: '/my-praise',
  component: () => import('@/views/MyPraise'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-money-extract',
  path: '/my-money-extract',
  component: () => import('@/views/MyMoneyExtract'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-coupon',
  path: '/my-coupon',
  component: () => import('@/views/MyCoupon'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-mall',
  path: '/my-mall',
  component: () => import('@/views/MyMall'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-order',
  path: '/my-order',
  component: () => import('@/views/MyOrder'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-order-details',
  path: '/my-order-details',
  component: () => import('@/views/MyOrderDetails'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-order-evaluate',
  path: '/my-order-evaluate',
  component: () => import('@/views/MyOrderEvaluate'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-survey',
  path: '/my-survey',
  component: () => import('@/views/MySurvey'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-information',
  path: '/my-information',
  component: () => import('@/views/MyInformation'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-cooperation',
  path: '/my-cooperation',
  component: () => import('@/views/MyCooperation')
}, {
  name: 'my-integral',
  path: '/my-integral',
  component: () => import('@/views/MyIntegral'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'my-invoice',
  path: '/my-invoice',
  component: () => import('@/views/MyInvoice')
}, {
  name: 'add-article',
  path: '/add-article',
  component: () => import('@/views/AddArticle'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'content-article',
  path: '/content-article',
  component: () => import('@/views/ContentArticle')
}, {
  name: 'content-route',
  path: '/content-route',
  component: () => import('@/views/ContentRoute')
}, {
  name: 'content-place',
  path: '/content-place',
  component: () => import('@/views/ContentPlace')
}, {
  name: 'content-team',
  path: '/content-team',
  component: () => import('@/views/ContentTeam')
}, {
  name: 'notice',
  path: '/notice',
  component: () => import('@/views/Notice')
}, {
  name: 'notice-details',
  path: '/notice-details',
  component: () => import('@/views/NoticeDetails')
}, {
  name: 'order-edit',
  path: '/order-edit',
  component: () => import('@/views/OrderEdit')
}, {
  name: 'order-date',
  path: '/order-date',
  component: () => import('@/views/OrderDate')
}, {
  name: 'order-details',
  path: '/order-details',
  component: () => import('@/views/OrderDetails')
}, {
  name: 'order-user',
  path: '/order-user',
  component: () => import('@/views/OrderUser')
}, {
  name: 'activity',
  path: '/activity',
  component: () => import('@/views/Activity')
}, {
  name: 'activity-details',
  path: '/activity-details',
  component: () => import('@/views/ActivityDetails')
}, {
  name: 'ticket-plane',
  path: '/ticket-plane',
  component: () => import('@/views/TicketPlane')
}, {
  name: 'ticket-plane-date',
  path: '/ticket-plane-date',
  component: () => import('@/views/TicketPlaneDate')
}, {
  name: 'ticket-plane-book',
  path: '/ticket-plane-book',
  component: () => import('@/views/TicketPlaneBook')
}, {
  name: 'ticket-plane-edit',
  path: '/ticket-plane-edit',
  component: () => import('@/views/TicketPlaneEdit'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'ticket-plane-details',
  path: '/ticket-plane-details',
  component: () => import('@/views/TicketPlaneDetails'),
  meta: {
    requiresAuth: true
  }
}, {
  name: 'service',
  path: '/service',
  component: () => import('@/views/Service')
}, {
  name: 'user',
  path: '/user',
  component: () => import('@/views/User')
}, {
  name: 'liberty',
  path: '/liberty',
  component: () => import('@/views/Liberty')
}, {
  name: 'team',
  path: '/team',
  component: () => import('@/views/Team')
}, {
  name: 'notes',
  path: '/notes',
  component: () => import('@/views/Notes')
}, {
  name: 'download',
  path: '/download',
  component: () => import('@/views/Download')
}, {
  name: 'pay',
  path: '/pay',
  component: () => import('@/views/Pay')
}, {
  name: 'pay-zhongjin-index',
  path: '/pay-zhongjin-index',
  component: () => import('@/views/PayZhongjinIndex')
}, {
  name: 'pay-zhongjin-info',
  path: '/pay-zhongjin-info',
  component: () => import('@/views/PayZhongjinInfo')
}, {
  name: 'pay-zhongjin-card',
  path: '/pay-zhongjin-card',
  component: () => import('@/views/PayZhongjinCard')
}, {
  name: 'pay-zhongjin-card-plus',
  path: '/pay-zhongjin-card-plus',
  component: () => import('@/views/PayZhongjinCardPlus')
}, {
  name: 'pay-zhongjin-pay',
  path: '/pay-zhongjin-pay',
  component: () => import('@/views/PayZhongjinPay')
}, {
  name: 'pay-zhongjin-ok',
  path: '/pay-zhongjin-ok',
  component: () => import('@/views/PayZhongjinOk')
}]
// 创建路由实例
const router = createRouter({
  history: createWebHashHistory(),
  mode: 'history',
  routes
})

if (storage.get('token')) {
  store.commit('setToken', storage.get('token'))
}
// 路由拦截
router.beforeEach((to, from, next) => {
  // 判断是否是登录保护路由
  // if (to.meta.title) {
  //   document.title = '绿马出游-' + to.meta.title
  // }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 验证浏览器本地存储是否有token（或从vuex、Cookie、localStorage中获取，依据自己的存储），用于判断是否登录
    if (store.state.token) {
      next() // 有token,进行request请求，后台还会验证token
    } else {
      next({
        path: '/login'
        // 将刚刚要去的路由path（却无权限）作为参数，方便登录成功后直接跳转到该路由，这要进一步在登陆页面判断
        // query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

// 创建登录组件
export default router
// 指定出口
