import Vuex from 'vuex'
import storage from '@/storage/index'

const store = new Vuex.Store({

  /** 公共数据state **/
  state: {
    token: '',
    city: '',
    loginInfo: '',
    userInfo: '',
    payInfo: '',
    toastState: false
  },

  /** 获取数据要通过的getters **/
  getters: {
    getToken (state) {
      return state.token || storage.get('token') || ''
    },
    getCity (state) {
      return state.city || storage.get('city') || ''
    },
    getLoginInfo (state) {
      return state.loginInfo || storage.get('loginInfo') || ''
    },
    getUserInfo (state) {
      return state.userInfo || storage.get('userInfo') || ''
    },
    getPayInfo (state) {
      return state.payInfo || storage.get('payInfo') || ''
    },
    getToastState (state) {
      return state.toastState
    }
  },

  /** 修改数据要通过的mutations **/
  mutations: {
    // 修改token，并将token存入localStorage
    setToken (state, token) {
      state.token = token
      storage.set('token', token)
    },
    delToken (state) {
      state.token = ''
      storage.remove('token')
    },
    // 修改地区
    setCity (state, city) {
      state.city = city
      storage.set('city', city)
    },
    delCity (state) {
      state.city = ''
      storage.remove('city')
    },
    // 修改登录信息
    setLoginInfo (state, loginInfo) {
      state.loginInfo = loginInfo
      storage.set('loginInfo', loginInfo)
    },
    delLoginInfo (state) {
      state.loginInfo = ''
      storage.remove('loginInfo')
    },
    // 修改用户信息
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
      storage.set('userInfo', userInfo)
    },
    delUserInfo (state) {
      state.userInfo = ''
      storage.remove('userInfo')
    },
    // 修改支付信息
    setPayInfo (state, payInfo) {
      state.payInfo = payInfo
      storage.set('payInfo', payInfo)
    },
    delPayInfo (state) {
      state.payInfo = ''
      storage.remove('payInfo')
    },
    // 修改提示状态
    setToastState (state) {
      state.toastState = true
    },
    delToastState (state) {
      state.toastState = false
    }
  },

  /** 异步处理可能用上的actions **/
  actions: {}
})

export default store
